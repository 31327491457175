import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"


export const DevelopmentProjectHeadline = ({ data, lang }) => {
    const [trigger, anim] = useScrollAnim()

    return (
        <section className="pt-main pb-4" ref={trigger}>
            <div className="container mw-xl">
                <h2 className={`${anim(1)}`}>{data?.title}</h2>
                <p className={`${anim(2)}`}>{data?.text}</p>
            </div>
        </section>
    )
}