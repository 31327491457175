import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "components/seo"
import Layout from "components/layout"
import { GlobalCover } from "components/global/cover/cover"
import { DevelopmentProjectHeadline } from "components/pages/development/project/headline"
import { DevelopmentProjectList } from "components/pages/development/project/list"


const DevelopmentProject = ({ pageContext, path, location }) => {
  const data = useStaticQuery(GET_ALL_LOCATIONS)
  const lang = pageContext.langKey

  const { projectCategories, backgroundColor, seo } = lang === "en"
    ? pageContext?.data
    : pageContext?.data?.translation
    || pageContext?.data

  const { cover, headline } = projectCategories
  const { slug, projects } = pageContext.data
  const allLocations = data.wordPress.projectLocations.nodes
  const tags = data.wordPress.projects.nodes

  const SEODefaultImg = cover.image.sourceUrl

  return (
    <Layout path={path} lang={lang} theme={backgroundColor.backgroundColor.color || "light"}>
      <SEO
        title={seo.title}
        desc={seo.metaDesc}
        fbImg={SEODefaultImg}
        fbTitle={seo.opengraphTitle}
        fbDesc={seo.opengraphDescription}
        url={location.href}
        twitterTitle={seo.twitterTitle}
        twitterDesc={seo.twitterDescription}
        twitterImg={SEODefaultImg}
      />
      <GlobalCover data={cover} theme={backgroundColor.backgroundColor.color || "light"} breadcrumb={path} />
      <DevelopmentProjectHeadline data={headline} lang={lang} />
      <DevelopmentProjectList
        lang={lang}
        options={allLocations}
        slug={slug}
        list={projects}
        tags={tags}
      />
    </Layout>
  )
}

export default DevelopmentProject

const GET_ALL_LOCATIONS = graphql`
query AllLocations {
    wordPress {
      projectLocations(first: 1000) {
        nodes {
          name
          slug
        }
      }
      projects(where: {language: EN}, first: 10000) {
        nodes {
          slug
          projectTags(first: 10000) {
            nodes {
              name
            }
          }
        }
      }
    }
  }
  
`