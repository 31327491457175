import React, { useState, useContext, useEffect } from "react"

import { Select } from "components/anti/forms/forms"
import { Card } from "components/anti/card/card"
import { Input } from "components/anti/forms/forms"
import { useScrollAnim } from "src/components/hooks/hooks"

import location from "assets/img/development/location.svg"
import building from "assets/img/development/building.svg"

export const DevelopmentProjectList = ({ options, slug, list, tags, lang }) => {
	const [trigger, anim] = useScrollAnim()

	const [projects, setProjects] = useState([])
	const [searchInput, setSearchInput] = useState('')

	useEffect(() => {
		let tagAdded = list.nodes.map(item => {
			tags.forEach(tag => {
				if (item.slug === tag.slug && tag.projectTags.nodes[0]?.name) {
					item['tag'] = tag.projectTags.nodes[0]?.name
				}
			})
			return item
		})
		tagAdded = tagAdded.sort(a => a.tag ? -1 : 1);
		setProjects(tagAdded)
	}, [])

	const allLocations = [
		{ value: "", label: `${lang === "en" ? "All Locations" : "Semua Lokasi"}` },
		...options.map((loc) => ({ value: loc.slug, label: loc.name }))
	]
	const [locations, setLocations] = useState(allLocations[0])

	const handleChange = (id, args) => {
		let filter = list.nodes
		if (args.value) {
			filter = list.nodes.filter(item => item.projectLocations.nodes[0]?.slug == args.value)
		}
		setLocations(args)
		setProjects(filter)
	}
	const changeProjects = (e) => {
		let { value } = e.target
		let filter = list.nodes

		if (value) {
			filter = list.nodes.filter(item =>
				item.title.toLowerCase().includes(value.toLowerCase())
			)
		}
		setProjects(filter)
		setSearchInput(value)
	}

	const clearInput = () => {
		setSearchInput("")
		setProjects(list.nodes)
	}

	useEffect(() => {
		const formField = document.querySelector(".icon-search-bar")
		const timesIcon = document.querySelector(".times-wrapper")

		if (searchInput.length > 0) {
			formField.classList.remove("ic-right")
			timesIcon.classList.remove("d-none")
			timesIcon.classList.add("d-block")
		} else {
			formField.classList.add("ic-right")
			timesIcon.classList.remove("d-block")
			timesIcon.classList.add("d-none")
		}
	}, [searchInput])
	return (
		<section className="pb-main sc-development-list" ref={trigger}>
			<div className="container mw-xl pb-5">
				<div className="row">
					<div className="col-md-4">
						<Select
							variant="underline"
							id="locations"
							name="locations"
							value={locations}
							options={allLocations}
							onChange={handleChange}
						/>
					</div>
					<div className="col-md-4">
						<Input
							variant="underline"
							placeholder={lang === "en" ? "Search by name" : "Cari berdasarkan nama"}
							value={searchInput}
							onChange={changeProjects}
							iconRight
							wrapperClassName="icon-search-bar"
							className={`search-bar position-relative`}
						>
							<i className="air ai-times times-wrapper d-none" onClick={() => clearInput()} />
						</Input>
					</div>
				</div>
			</div>
			<div className="container px-sm-down-0">
				{projects.length <= 0 && (
					<p className="text-muted text-center">{lang === "en" ? "No results found. Try another name." : "Tidak ada hasil yang ditemukan. Coba nama lain"}</p>
				)}
				<div className="row row-3">
					{projects?.map((project, i) => {
						const dataLang = lang === "en"
							? project?.projectDetailsPage
							: project?.translation?.projectDetailsPage
							|| project?.projectDetailsPage

						const cover = dataLang?.cover
						const altText = cover.image.altText
						const locationName = project?.projectLocations?.nodes[0]?.name
						const categoriesName = project?.projectCategories?.nodes[0]?.name
						return (
							<div className="col-md-6 col-lg-4">
								<Card
									variant="overlay"
									img={cover.image.sourceUrl}
									imgAlt={altText ? altText : "Image"}
									title={cover.title}
									imgRatio="r-4-3"
									className={`mb-md-5 development-card ${anim(3 + i)}`}
									link={`${lang === "en" ? "" : "/id"}/development/${slug}/${project.slug}`}
									key={i}
								>
									{project.tag && <small className="sm-tag-image">{project.tag}</small>}
									<div className="d-flex">
										<div className="d-flex mr-3">
											<img src={location} className="img-fluid" alt="icon" />
											<small className="text-white text-truncate">{locationName}</small>
										</div>
										<div className="d-flex">
											<img src={building} className="img-fluid" alt="icon" />
											<small className="text-white text-truncate">{categoriesName}</small>
										</div>
									</div>
								</Card>
							</div>
						)
					})}
				</div>
			</div>
		</section>
	)
}